<template>
  <vue-pdf-app
    class="pdf-h"
    :pdf="termsOfUseDoc"
    :config="config"
  />
</template>

<script>
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import termsOfUseDoc from '../../assets/doc/termsOfUse.pdf'

export default {
  name: 'TermsOfUse',

  components: {
    VuePdfApp,
  },

  data() {
    return {
      termsOfUseDoc,
      config: {
        toolbar: false,
      },
    }
  },
}
</script>

<style scoped>
.pdf-h {
  height: 100vh;
}
</style>
